<script>
export default {}
</script>

<template>
  <section :class="$style.sectionContainer" class="position-relative">
    <div
      :class="$style.bgImg"
      class="position-absolute"
      :style="{
        backgroundImage:
          'radial-gradient(circle, rgba(0,0,0,0.2) 0%, rgba(0,0,0,1) 90%), linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,1) 80%), url(' +
          require('@assets/images/photo-groupe-corporate-paris-1.png') +
          ')',
      }"
    ></div>
    <div class="container">
      <div class="row">
        <div class="col-md-6 order-md-1">
          <div
            :class="$style.block"
            class="d-flex flex-column justify-content-center h-100"
          >
            <h3 :class="$style.topTitle" class="text-color-pink title-section">
              A propos de nous
            </h3>
            <h2
              :class="$style.title"
              class="font-weight-bolder title-section-desc"
            >
              Une équipe impliquée
            </h2>
            <p :class="$style.para" class="text-justify">
              Xenius est une famille jeune, dynamique, flexible aux opinions, à
              la culture et aux passions diverses. Nous sommes unis par notre
              volonté de répondre aux besoins de chaque client. C’est avec notre
              esprit d’équipe, notre partage et notre travail collaboratif que
              nous déterminons les traits de communication les plus efficaces.
            </p>
            <p>Nos experts sont à votre écoute !</p>
            <!-- <button class="btn ">
              <span class="circle bg-color-pink"> </span>
              <span class="button-text">Nous connaître davantage</span>
            </button> -->
          </div>
        </div>
        <div class="col-md-6 order-md-0">
          <div class="image-grid">
            <div class="row">
              <div class="col pb-4 text-right">
                <img
                  :src="
                    require('@assets/images/photo-groupe-corporate-paris-1.png')
                  "
                  :class="[$style.aboutImage, $style.aboutImageFirst]"
                  alt="About Photo 1"
                />
              </div>
            </div>
            <div class="row">
              <div class="d-none d-md-block col-12 col-md-7 px-4">
                <img
                  :src="
                    require('@assets/images/photo-groupe-corporate-paris-2.png')
                  "
                  :class="$style.aboutImage"
                  alt="About Photo 1"
                />
              </div>
              <div class="d-none d-md-block col px-4 py-5">
                <img
                  :src="
                    require('@assets/images/photo-groupe-corporate-paris-3.png')
                  "
                  :class="$style.aboutImage"
                  alt="About Photo 1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" module>
@import '@design';
.sectionContainer {
  .bgImg {
    top: -10%;
    right: 10%;
    bottom: 0;
    left: -20%;
    z-index: -2;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: cover;
  }
  .aboutImage {
    max-width: 100%;
    &First {
      max-width: 80%;

      @include respond-below(md) {
        max-width: 100%;
      }
    }
  }
}
.block {
  .topTitle {
  }

  .title {
  }

  .para {
    max-width: 30em;
  }
}
</style>
