<script>
import Layout from '@layouts/main.vue'
import AProposComponent from '@components/a-propos-component.vue'

export default {
  page: {
    title:
      'Xenius Consulting, agence web, site internet, création graphique, SEO',
    meta: [
      {
        name: 'description',
        content:
          'Agence de communication pour la création de votre site internet de A à Z, création graphique, stratégie de communication digitale, SEO, SEA ...',
      },
    ],
  },
  components: { Layout, AProposComponent },
  data() {
    return {
      settings: {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 4,
        initialSlide: 0,
        centerPadding: '50px',
      },
    }
  },
}
</script>

<template>
  <Layout>
    <section>
      <div
        :class="$style.headerBg"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 100%), url(' +
            require('@assets/images/contact-bg.png') +
            ')',
        }"
      ></div>
      <div class="container">
        <div :class="$style.paddingTop">
          <div class="row">
            <div class="col col-lg-7 col-md-7">
              <h2
                :class="$style.pageTitle"
                class="font-weight-bolder title-section-desc text-color-pink"
                >Qui sommes nous ?</h2
              >
              <!-- <h3 class="font-weight-bolder title-section">Loren Epsom dolor eat.</h3> -->
            </div>
          </div>
          <div class="row mb-5">
            <div class="col col-md-5 col-sm-10">
              <p>
                Xenius Consulting construit des stratégies marketing digitales
                sur mesure. Nous mettons notre expertise à votre service pour
                vous aider à adapter les outils digitaux adéquats à votre
                métier. Nos ambitions sont de vous accompagner dans la mise en
                place d’une stratégie marketing digitale efficace et durable.
                Nous vous garantissons une présence effective sur le web et un
                vrai retour sur investissement. Nous vous proposons une gamme
                étendue de solutions marketing digitales.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      :style="{
        backgroundImage:
          'linear-gradient(0deg,rgb(4, 4, 4) 0%, rgba(0, 0, 0, 0.64) 30%, rgba(6, 0, 0, 0.7) 100%), url(' +
          require('@assets/images/nous-somme-bg.png') +
          ')',
      }"
      :class="$style.notreMission"
      class="padder-50 position-relative notre-mission"
    >
      <div :class="$style.missionBg"></div>
      <div class="container">
        <div class="row">
          <div class="col-sm-12 col-md-6 m-t-80">
            <h1
              class="text-color-pink font-weight-bolder text-uppercase title-section"
              >Notre mission</h1
            >
            <!-- <h2 class="font-weight-bolder title-section-desc">Lorem Ipsum</h2> -->
            <p class="paragraph-limit"
              >Xenius Consulting aide les entreprises à adopter les
              incontournables tendances du marketing digital. Nos équipes
              compétentes et expérimentées mettent à votre disposition une
              palette complète de services. Nous axons nos activités sur :</p
            >
          </div>
          <div class="col-sm-12 col-md-6">
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-1.svg')"
                    alt
                  />
                  Design graphique +
                </h3>
              </div>
              <div :class="$style.para">
                <p>
                  BESOIN DE DÉFINIR UNE IDENTITÉ VISUELLE IMPACTANTE POUR VOS
                  PROJETS ? Pensez à travailler l’esthétique de vos supports
                  digitaux avec une agence créative ! Chez Xenius Consulting,
                  nous traduisons le message que vous souhaitez véhiculer d’une
                  manière visuelle harmonieuse et captivante. Forte d’une
                  culture artistique solide, notre équipe de designers
                  polyvalents intervient à chaque étape de la chaîne graphique.
                </p>
              </div>
            </div>
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-3.svg')"
                    alt
                  />
                  Audiovisuel +</h3
                >
              </div>
              <div :class="$style.para">
                <p
                  >Pensez à intégrer l’audiovisuel dans votre stratégie de
                  communication digitale! Xenius Consulting vous permet de
                  gagner en visibilité grâce à un contenu audiovisuel innovant
                  et à forte valeur ajoutée. De la rédaction des scénarios,
                  passant par la production à la diffusion des vidéos, nos
                  rédacteurs et monteurs s’allient pour traduire vos messages en
                  un contenu audiovisuel captivant et sur-mesure.</p
                >
              </div>
            </div>
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-4.svg')"
                    alt
                  />
                  Webmarketing +</h3
                >
              </div>
              <div :class="$style.para">
                <p>
                  BESOIN D’OPTIMISER VOTRE VISIBILITÉ SUR LE WEB ? Adoptez une
                  stratégie de communication digitale efficace avec une agence
                  créative. Chez Xenius Consuting, nous concevons des stratégies
                  webmarketing sur mesure. Nous apportons une réponse aux enjeux
                  stratégiques de vos entreprises : notoriété & image de marque,
                  extension du périmètre de prospection et fidélisation des
                  clients.
                </p>
              </div>
            </div>
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-2.svg')"
                    alt
                  />
                  Solutions Web & Mobile +</h3
                >
              </div>
              <div :class="$style.para">
                <p
                  >Chez Xenius Consulting, nous offrons la solution complète !
                  Nos développeurs conçoivent des sites web responsives et des
                  applications mobiles ergonomiques avec un design moderne et
                  des fonctionnalités poussées.</p
                >
              </div>
            </div>
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-5.svg')"
                    alt
                  />
                  Maintenance & webmastering +</h3
                >
              </div>
              <div :class="$style.para">
                <p>
                  BESOIN D’UN SITE PERFORMANT, SÉCURISÉ ET MIS-À-JOUR? Xenius
                  Consulting maintient votre site internet en bonne santé. Nos
                  webmasters assurent le suivi et la mise à jour quotidienne de
                  vos sites web. Ils corrigent les dysfonctionnements pour vous
                  offrir une exploration rapide et efficace.
                </p>
              </div>
            </div>
            <div :class="$style.sideNotes">
              <div :class="$style.title">
                <h3 class="text-color-pink">
                  <img
                    :src="require('@assets/images/icons/nous-sommes-ico-6.svg')"
                    alt
                  />
                  Stratégie de contenu +</h3
                >
              </div>
              <div :class="$style.para">
                <p>
                  BESOIN DE DÉPLOYER UNE STRATÉGIE DE CONTENU PERTINENTE ?
                  Xenius consulting améliore vos stratégies éditoriales. Nos
                  rédacteurs web traduisent vos objectifs en un contenu adapté
                  aux besoins de votre entreprise et de votre cible. Un contenu
                  de qualité permet d’amener de la notoriété, du trafic, des
                  clics et de générer des ventes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AProposComponent />
    <section :class="$style.quote">
      <div class="container position-relative">
        <div :class="$style.quoteContainer" class="text-center">
          <p class="text-center">‘’Vivez l'expérience avec nous‘’</p>
        </div>
      </div>
    </section>
    <!-- <section class="mt-5">
      <div class="container">
        <div class="row">
          <div class="col">
            <h3 class="text-color-pink font-weight-bolder text-uppercase title-section">Notre Equipe</h3>
            <h4 class="font-weight-bolder title-section-desc"> une équipe engagée, performante et dynamique</h4>
          </div>
        </div>
        <VueSlickCarousel v-bind="settings">
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
          <div class="member">
            <div class="picture">
              <img :src="require(`@assets/images/member.png`)" alt />
            </div>
            <div class="info">
              <h4>Foulen ben Foulen</h4>
              <span class="position">Directeur Artistique</span>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </section>  -->
  </Layout>
</template>

<style lang="scss" module>
@import '@design';
.headerBg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 75vh;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.missionBg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 30%;
  height: 55vh;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.quote {
  overflow: hidden;

  .quoteContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    text-align: center;
    &::before {
      position: absolute;
      top: 0;
      left: -50%;
      z-index: -1;
      width: 200%;
      height: 100%;
      content: '';
      background: $color-theme-pink;
    }
    p {
      font-size: 40px;
      font-weight: 500;
      line-height: 4vh;
      word-wrap: break-word;
    }
  }
}
.paddingTop {
  padding-top: 30vh;
}
.sideNotes {
  .title h3 {
    color: $color-theme-pink;
    img {
      margin-right: 15px;
    }
  }

  p {
    font-size: 14px;
  }
}
.notreMission {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 40% 80%;
}
.sliderContainer {
}
p {
  text-align: justify;
}
</style>
